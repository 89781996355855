// common colors
$white: white;
$error-color: #f44336;

// theme colors
$theme-color: #3f51b5;
$lighten-color: lighten($theme-color, 50%);

// custom colors
$black-54-opacity: rgba(0, 0, 0, 0.54);
$black-20-opacity: rgba(0, 0, 0, 0.2);
$black-03-opacity: rgba(0, 0, 0, 0.03);
$black-06-opacity: rgba(0, 0, 0, 0.06);

$mat-list-hover-color: #f2f2f2;
$sidenav-active-color: #d9d9d9;
$mat-form-field-header-color: rgba(0, 0, 0, .54);

$base-color-warning: #f9a800; // ISO 3864
$base-color-draft: #f1c40f;
$base-color-info: #3498db;
$base-color-active: #2ecc71;
$base-color-disabled: #e74c3c;
$base-color-inactive: #808080;

$green: darken($base-color-active, 10%);

// event status colors
$event-status-color-draft: $base-color-draft;
$event-status-color-preannouncement: $base-color-info;
$event-status-color-active: $base-color-active;
$event-status-color-archive: $base-color-disabled;

// news status colors
$news-status-color-draft: $base-color-draft;
$news-status-color-published: $base-color-active;
$news-status-color-disabled: $base-color-disabled;

// sizes
$icon-size: 24px;
$sidebar-title-size: 14px;

// shadow
$box-shadow-file-upload: 0 3px 1px -2px rgba(0, 0, 0, .2), 0 2px 2px 0 rgba(0, 0, 0, .14), 0 1px 5px 0 rgba(0, 0, 0, .12);

// rating
$rating-star-color: #f2b01e;

// session visibility colors
$session-visibility-private-color: $base-color-inactive;
$session-visibility-optional-color: $base-color-info;
$session-visibility-normal-color: $base-color-active;

// session type colors
$session-type-sessions-color: $base-color-active;
$session-type-break-color: $base-color-inactive;
