@import 'variables';
@import 'mixins';

body {
  font-family: "Roboto", "Helvetica Neue Light", "Helvetica Neue", "elvetica", "Arial", "Lucida Grande", "sans-serif";
  margin: 0;
}

.mat-card {
  margin: 5px;
}

app-main-page {
  .mat-drawer-container[fullscreen] {
    top: 64px;
  }
}

mat-sidenav-content {
  padding: 20px;
}

mat-row {
  &.hover-row {
    &:hover {
      background: $lighten-color;
    }
  }

  &.clickable-row {
    cursor: pointer;
  }
}

mat-row,
mat-cell {
  &:focus {
    outline: none;
  }
}

.no-data-message {
  background: rgba(204, 204, 204, 0.2);
  margin: 0;
  padding: 15px;
  font-size: 14px;
  color: rgba(0, 0, 0, .87);
  text-align: center;
}

.search-field {
  width: 300px;
}

.mat-table {
  .mat-column-actions {
    max-width: 85px;
    text-align: center;
    margin-left: auto;

    .mat-icon-button {
      &:not(.mat-button-disabled):hover {
        cursor: pointer;
        opacity: .5;
      }
    }
  }
}

.button-icon {
  float: left;
  margin-top: 6px;
  margin-right: 6px;
}

@include fa-mat-icon {
  display: inline-flex;
  align-items: center;
  justify-content: center;
}
