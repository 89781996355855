@mixin center-elem-inside-mat-button() {
  ::ng-deep {
    &.mat-button-wrapper {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}

@mixin pill($accent-color, $text-color: white, $background-color: $accent-color) {
  border: solid 1px $accent-color;
  display: inline-block;
  padding: 3px 10px;
  background: $background-color;
  color: $text-color;
  border-radius: 20px;
  font-size: 12px;
  vertical-align: middle;
}

@mixin rounded-edge($accent-color, $text-color: white, $background-color: $accent-color) {
  @include pill($accent-color, $text-color, $background-color);

  border-radius: 5px;
}

@mixin remove-focus-outline {
  &:focus {
    outline: 0;
  }
}

@mixin reset {
  p {
    margin: 0;
  }
}

@mixin mat-icon-size($size: 24px) {
  height: $size;
  width: $size;
  font-size: $size;
}

@mixin cell-background-image($image-size: 40px) {
  background-size: cover;
  background-position: center;

  width: $image-size;
  height: $image-size;
}

@mixin absolute-center() {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@mixin background-pattern-rectangles() {
  background-image: linear-gradient(45deg, #dddddd 25%, transparent 25%), linear-gradient(-45deg, #dddddd 25%, transparent 25%), linear-gradient(45deg, transparent 75%, #dddddd 75%), linear-gradient(-45deg, transparent 75%, #dddddd 75%);
  background-size: 20px 20px;
  background-position: 0 0, 0 10px, 10px -10px, -10px 0px;
}

@mixin fa-mat-icon() {
  mat-icon {
    &[fontset="fal"],
    &[fontset="fas"],
    &[fontset="far"],
    &[fontset="fad"],
    &[fontset="fat"],
    &[fontset="fab"],
    &[ng-reflect-font-set="fal"],
    &[ng-reflect-font-set="fas"],
    &[ng-reflect-font-set="far"],
    &[ng-reflect-font-set="fad"],
    &[ng-reflect-font-set="fat"],
    &[ng-reflect-font-set="fab"] {
      @content;
    }
  }
}

@mixin line-clamp($max-line: 2) {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
  display: -webkit-box;
  /* autoprefixer: ignore next */
  -webkit-line-clamp: $max-line;
  /* autoprefixer: ignore next */
  -webkit-box-orient: vertical;
}

@mixin ellipsis($max-width: 300px) {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: block;
  max-width: $max-width;
}
